 
/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* General Styles */
body, html {
    font-family: Arial, sans-serif;
    //background-color: #2e2e2e;
    background-color: #032637;
    color: #e0e0e0;
    height: 100%;
    width: 100%;
    overflow: hidden; /* Prevent double scrollbars */
}

body.light-mode, html.light-mode {
    background-color: white;
}

div {
    display: flex !important;
    flex-direction: column;
}


// Stuff for light and dark mode ///////////
.logoutdiv {
    display: flex !important;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Center the content horizontally by default */
    position: relative; /* Enable absolute positioning for children */
    // background-color: #333;
    background-color: #032637;
  }

body.light-mode .logoutdiv {
    background-color: white;
}
  
.switch {
    position: absolute;
    margin-left: 5px;
    left: 0;  /* Align the toggle flush left */
    display: inline-block;
    width: 50px;
    height: 25px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 25px;
    transition: 0.4s;
  }
  
  .slider::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 2.5px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #074F71;
  }
  
  input:checked + .slider::before {
    transform: translateX(25px);
  }
  
  
  .logo {
    width: 150px;
    height: auto;
    margin-top: 7px;
    margin-bottom: 5px;
  }
  
  .logout-btn {
    position: absolute; /* Position the logout button relative to the container */
    right: 0; /* Push it flush to the right */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #074F71;
    color: white;
    cursor: pointer;
    font-size: 12px;
  }
///////////////////////////////////

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh; /* Use min-height to account for dynamic viewport changes */
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
    padding-bottom: 3%; /* Padding for small screens */
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 5px;
    // background-color: #333;
    background-color: #032637;
}

body.light-mode .header{
    background-color: white;
    // color:#074F71
    color: #414042
}

h1 {
    font-size: 15px;
}

.chat-window {
    display: flex !important;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
    // background-color: #444;
    background-color: #032637;
    padding: 15px;
    padding-bottom: 20%;
    border-radius: 5px;
    max-height: 100vh;
}

body.light-mode .chat-window {
    // background-color: #ccc;
    background-color: #D9D9D9;
}


.message {
    padding: 15px;
    border-radius: 20px;
    margin-bottom: 10px;
    max-width: 65%;
    word-wrap: break-word;
}

.user-message {
    // background-color: #074F71;
    background-color: #808080;
    color: white;
    align-self: flex-end;
    text-align: left;
}

body.light-mode .user-message {
    background-color: #808080;
}

.bot-message {
    // background-color: #3e3e3e;
    background-color: #074F71;
    color: #e0e0e0;
    align-self: flex-start;
    text-align: left;
}

body.light-mode .bot-message {
    // background-color: #007bff;
    background-color: #074F71;
    color: white;
}

.reminder-message {
    //background-color: #3e3e3e;
    background-color: #074F71;
    color: #e0e0e0;
    align-self: flex-start;
    text-align: left;
    margin-top: 10px;
}

body.light-mode .reminder-message {
    // background-color: #007bff;
    background-color: #074F71;
    color: white;
}

.reminder {
    color: white;
    font-size: 14px;
    padding: 5px;
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin: 0px auto; /* Space it properly */
}

body.light-mode .reminder {
    color: black
}

form {
    display: flex;
    flex-shrink: 0;
    padding: 10px;
    // background-color: #333;
    background-color: #032637;
    position: sticky; /* Keeps the form visible at the bottom */
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* Adds shadow for better visibility */
}

body.light-mode form {
    background-color: white;
}


textarea {
    flex-grow: 1;
    padding: 10px;
    //border: 1px solid #555;
    border: 1px solid #808080;
    border-radius: 5px;
    //background-color: #3e3e3e;
    background-color: #808080;
    color: white;
    font-size: 16px;
    resize: none;
    overflow-y: hidden;

}

textarea::placeholder {
    color: white;
    opacity: 0.75;
}

body.light-mode textarea {
    border: 1px solid #ccc;
    // background-color: #ccc;
    background-color: #D9D9D9;
    color: black;
}

body.light-mode textarea::placeholder {
    color: #555;
    opacity: 0.6;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    // background-color: #074F71;
    background-color: #D86018;
    color: white;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;

    &.special-submit {
        background-color: #ff5733;
        color: white;
        border: none;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;}
}

body.light-mode .submit-button {
    background-color: #D86018;
}

button:hover {
    background-color: #5489A3;
}

.submit-button:hover {
    // background-color: #5489A3;
    background-color: #B04A14;
}

body.light-mode .submit-button:hover {
    background-color: #B04A14;
}

/* Loading Dots Animation */
.loading-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
}

.dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    // background-color: #007bff;
    background-color: white;
    border-radius: 50%;
    animation: dot-flash 1.2s infinite ease-in-out both;
}

body.light-mode .dot {
    background-color: white;
}

@keyframes dot-flash {
    0%, 80%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    40% {
        transform: scale(1.5);
        opacity: 0.5;
    }
}

.dot:nth-child(1) {
    animation-delay: -0.32s;
}

.dot:nth-child(2) {
    animation-delay: -0.16s;
}

.dot:nth-child(3) {
    animation-delay: 0;
}

/* Feedback Section */
.feedback {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    justify-content: flex-start;
}

.feedback button {
    background: none;
    border: none;
    //color: #e0e0e0;
    cursor: pointer;
    margin-right: 0px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 25%;
    // transition: background-color 0.3s;
}

// .feedback button:hover {
//     background-color: rgba(255, 255, 255, 0.1);
// }

// body.light-mode .feedback button:hover {
//     background-color: white;
// }

.feedback-form {
    margin-top: 10px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.feedback-form textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    // border: 1px solid #555;
    border: 1px solid #808080;
    // background-color: #3e3e3e;
    background-color: #808080;
    // color: #e0e0e0;
    color: white;
    resize: none;
}

body.light-mode .feedback-form textarea {
    background-color: white;
    color: black;
}

.feedback-form button {
    margin-top: 10px;
    background-color: #074F71;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    align-self: flex-start;
    width: auto;
}

.feedback-form button:hover {
    background-color: #5489A3;
}

.score-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.score-form label {
    font-weight: bold;
}

body.light-mode .score-form label {
    color: black;
}

.score-form input,
.score-form textarea {
    padding: 5px;
    border: 1px solid #ccc;
    background-color: #808080;
    color: white;
    border-radius: 5px;
    width: 100%;
}

.score-form input::placeholder {
    color: white;
    opacity: 0.8;
}

body.light-mode .score-form input,
body.light-mode .score-form textarea {
    background-color: white;
    color: black;
}

body.light-mode .score-form input::placeholder {
    color: #555;
    opacity: 0.6;
}

.score-form .error {
    color: red;
    font-size: 12px;
}

.score-submit:hover {
    background-color: #B04A14;
}

/* Media Queries for Responsiveness */

/* Large screens (desktops) */
@media only screen and (min-width: 768px) {
    .container {
        width: 70%;
        margin: 0 auto;
    }

    .message {
        max-width: 60%;
    }

    h1 {
        font-size: 20px;
    }
}

/* Small screens (mobile) */
@media only screen and (max-width: 1641px) {
    .container {
        width: 100%;
        padding: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Ensure space between chat and form */
        // max-height: calc(100vh - 150px); /* Ensures the container fills the viewport */
        height: 100vh
    }

    .header {
        padding: 10px;
    }

    h1 {
        font-size: 17px;
    }

    .chat-window {
        padding: 10px;
        flex-grow: 1;
        overflow-y: scroll;
        padding-bottom: 38%;
        max-height: 100vh; /* Adjusts height to prevent overflow */
    }

    form {
        display: flex;
        flex-shrink: 0;
        padding: 10px;
        // background-color: #333;
        background-color: #032637;
        position: sticky; /* Keeps the form visible at the bottom */
        bottom: 0;
        width: 100%;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* Adds shadow for better visibility */
    }

    body.light-mode form {
        background-color: white;
    }

    textarea {
        flex-grow: 1;
        padding: 10px;
        // border: 1px solid #555;
        border: 1px solid #808080;
        border-radius: 5px;
        // background-color: #3e3e3e;
        background-color: #808080;
        // color: #e0e0e0;
        color: white;
        font-size: 16px;
        resize: none;
        overflow-y: scroll;
        max-height: 20vh; /* Prevents the textarea from expanding too much */
    }

    body.light-mode textarea {
        background-color: #ccc;
        color: black;
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        // background-color: #074F71;
        background-color: #D86018;
        color: white;
        cursor: pointer;
        font-size: 16px;
        margin-left: 10px;
    }

    .feedback-form {
        width: 100%;
    }
}

// Modal Overlay
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
}

.modal-content {
    background: #ffffff;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    color: black;
    font-size: 1.2rem;
    overflow-y: auto;
    max-height: 90vh;
    position: relative;
  }

  .modal-title {
    margin-bottom: 10px;
  }
  
  .modal-content div {
    margin-top: 8px;  /* Add spacing between sections */
  }
  
  .indent-0 {
    margin-left: 0px;   /* No indentation for "1." */
  }
  
  .indent-1 {
    margin-left: 20px;  /* One level of indentation for "a." */
  }
  
  .indent-2 {
    margin-left: 40px;  /* Two levels of indentation for "1)" */
  }
  
  .indent-3 {
    margin-left: 60px;  /* Three levels of indentation for "a)" */
  }

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.2rem; /* Reduce font size */
    cursor: pointer;
    color: #333;
    width: 24px; /* Set a specific width */
    height: 24px; /* Set a specific height */
    line-height: 24px; /* Center the "X" vertically */
    padding: 0; /* Remove any default padding */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Optional: make it a small circle */
}

.abbreviated-title-button {
    background: none;
    border: none;
    color: white;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
    padding: 0;
    margin: 0;
    display: inline; /* Ensures it doesn’t behave like a block element */
}

.guide-button {
    // background-color: #007bff; /* Customize color */
    color: white;
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-top: 0.5rem;
}

body.light-mode .guide-button {
    color: black
}

.guide-button:hover {
    background-color: #0056b3; /* Darker shade for hover */
}

.special-buttons {
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    gap: 10px; /* Space between buttons */
    margin-top: 10px;
}

.special-buttons button {
    width: 100px; /* Reduce button width */
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    background-color: #0078d4; /* Optional: Button background color */
    color: #ffffff; /* Optional: Button text color */
    border: none;
    border-radius: 5px;
}

.special-buttons button:hover {
    background-color: #005a9e; /* Optional: Hover color */
}

.red-text {
    color: red; /* Change to red instead of highlight */
    font-weight: bold;
}

.highlight-text {
    background-color: yellow;  /* Or any preferred highlight color */
}

/////////// icon styles ////////////
.material-icons {
    font-size: 20px;
    color:#808080;
}

.material-symbols-outlined {
    font-size: 25px;
    color: #808080; 
}

.icon-button:hover .material-icons,
.icon-button:hover .material-symbols-outlined {
    color: #074F71;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 100%;
    margin-top: 30px;
}

.button-container button {
    background-color: #D65A1E; /* Matching orange color */
    color: white; /* White text */
    font-size: 16px;
    //font-weight: bold;
    text-transform: uppercase; /* Matches the text style in the screenshot */
    border: none;
    padding: 15px;
    width: 100%; /* Make buttons full width */
    max-width: 600px; /* Restrict width for better design */
    margin-bottom: 15px; /* Space between buttons */
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.button-container button:hover {
    background-color: #B94E1A; /* Slightly darker shade on hover */
}

.button-container button:active {
    background-color: #8F3C14; /* Even darker when clicked */
}


  




